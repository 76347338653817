import { Env } from './enums';
import { PropositionsType } from './types/Home';
import { ImageMapType, ImageSpecsData } from './types/Spaces';
import {
  getCountryCode,
  getEnvDetails as imgSrc,
  isNonProdEnv,
} from './utils/helper';

export const COUNT_LIMIT = 30;
export const FEED_COUNT_LIMIT = 15;
export const MODIFIED_HEADLINE_CHAR_LIMIT = 50;
export const COLLECTIONS_COUNT_LIMIT = 50;
export const PUBLISHER_COUNT_LIMIT = 20;
export const COUNT_LIMIT_SPACES = 40;
export const CTA_TEXT_LENGTH_LIMIT = 11;
export const GET_COLLECTIONS_MAX_SIZE = 20;
export const COLLECTIONS_DEFAULT_SIZE = 20;
export const COLLECTIONS_MIN_SIZE = 1;
export const COLLECTIONS_MAX_SIZE = 500;
export const TEST_CONSTANT = 'TEST';
export const UNKNOWN_ERROR = 'An unknown error occured.';
export const DEFAULT_STORY_DURATION = 1500;
export const HEADLINE_VIDEOTEXT_MAXLIMIT = 100;
export const VIDEO_START_TIME = 0;
export const VIDEO_END_TIME = 5;
export const VIDEO_MINCROP_DURATION = 5;
export const LS_LANGUAGE_HINDI = 'hi';
export const LS_LANGUAGE_ENGLISH = 'en';
export const GLANCIFICATION_IN_PROGRESS = 'glancification_in_progress';
export const GLANCIFICATION_FAILED = 'glancification_failed';
export const GLANCIFICATION_SUCCESS = 'glancification_completed';
export const UI_GLANCE_GENERATED = 'ui_glance_generated';
export const IMAGE_WIDTH = 720;
export const IMAGE_ASPECT_WIDTH = 9;
export const IMAGE_ASPECT_HEIGHT = 16;
export const IMAGE_SQUARE_ASPECT = 1;
export const BREAKING_NEWS_IMAGE_WIDTH = 80;
export const VIDEO_SIZE_LIMIT_SM = 100;
export const VIDEO_SIZE_LIMIT_LG = 900;
export const CONVERSION_UNIT = 1024;
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT_HHmm = 'YYYY-MM-DD HH:mm';
export const SYF_DATE_FORMAT = 'DD MMM YYYY';
export const XIAOMI = 'Xiaomi';
export const TRACFONE = 'Tracfone';
export const JIONEXT = 'JioNextNiu';
export const NOBG_TEMPLATE = 'noBGTemplate';
export const ADMIN = 'ADMIN';
export const ARTICLE = 'ARTICLE';
export const NATIVE_VIDEO = 'NATIVE_VIDEO';
export const DESIGNER_IMAGES = 'designer-images';
export const BINGE_MAGAZINE_CARD_GENARATION = 'binge-magazine-card-generation';
export const DEFAULT_TEMPLATE_POSITION = 'move_top';
export const PUBLISH_APPROVAL_PENDING = 'publish_approval_pending';
export const LS_SUMMARY_CHAR_LIMIT = 150;
export const SPACES_LS_SUMMARY_CHAR_LIMIT = 150;
export const DURATION_DAYS = 'DAYS';
export const DEFAULT_DURATION_DAYS = 2;
export const DEFAULT_START_TIME = 1;
export const DEFAULT_DURATION_TYPE = 1;
export const DURATION_HOURS = 'HOURS';
export const VIDEO_HEIGHT = '300';
export const EDIT_IMAGE = 'Edit Image';
export const CHANGE_IMAGE = 'Change Image';
export const IMAGE_FILE_SIZE_SPACES = 800; // Image size in KB
export const IMAGE_FILE_SIZE_FEED = 10240; // Image size in KB i.e. 10MB
export const IMAGE_FILE_SIZE_ROUNDUP = 150; // Image size in KB
export const IMAGE_FILE_SIZE_COLLECTION = 150; // Image size in KB
// Below format shows date and time in hh:mm format with AM/PM suffix
export const DATE_TIME_FORMAT_AMPM = 'DD-MM-YYYY 	hh:mm A  ';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const BREAKING_NEWS_WIDGET = 'BREAKING_NEWS_WIDGET';
export const AROUND_YOU_NEWS_WIDGET = 'AROUND_YOU_NEWS_WIDGET';
export const HEADLINES_WIDGET = 'HEADLINES_WIDGET';
export const ROUNDUP_SPACE_TYPE = 'ROUND_UP';
export const STORY_SPACE_TYPE = 'SYF';
export const CARD_TYPE_NEWSROOM = 'newsroom';
export const CARD_TYPE_FEATURE = 'feature';
export const CARD_TYPE_L0 = 'l0';

export const SOURCE_IDENTIFIERS = Object.freeze({
  FEED: 'FEED',
  SPACE: 'SPACE',
});

export const PUBLISHING_DESTINATIONS = Object.freeze({
  FEED: 'FEED',
  SPACES_V1: 'SPACES_V1',
  SPACES_V2: 'SPACES_V2',
});

export const designerImagePath = (cardId: string, imageName: string): string =>
  `https://cardpresssa.blob.core.windows.net/${
    imgSrc() === Env.DEV ? Env.STAGING : imgSrc()
  }/cardpress/binge-magazine-card-generation/${cardId}/designer-images/${imageName}.jpg`;

export const uploadedOverlayImagePath = (
  cardId: string,
  imageName: string,
): string =>
  `https://cardpresssa.blob.core.windows.net/${
    imgSrc() === Env.DEV ? Env.STAGING : imgSrc()
  }/cardpress/binge-magazine-card-generation/${cardId}/designer-overlay-images/${imageName}`;

export const fullImagePath = (imageName: string): string => {
  const imgUrl = isNonProdEnv()
    ? `https://x-stg.glance-cdn.com/public/ims/${imageName}.jpg`
    : `https://glance-mob.glance-cdn.com/public/ims/${imageName}.jpg`;
  return imgUrl;
};

export const verticalGradient =
  'linear-gradient(0deg, #17181E 0%, rgba(23, 24, 30, 0) 100%)';

export const verticalGradientLn =
  'linear-gradient(0deg, #17181E 0%, rgba(23, 24, 30, 0) 49.07%)';

export const horizontalGradient =
  'linear-gradient(89.87deg, #17181E 52.42%, rgba(29, 29, 35, 0.6) 63.39%, rgba(29, 29, 35, 0) 82.85%)';

export const verticalGradientL0S =
  'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.24) 128.65%)';

export const verticalGradientTopStory =
  'linear-gradient(0deg, rgba(0, 0, 0, 0.83) 5.81%, rgba(0, 0, 0, 0) 100%)';

export const breakingNewsL0MConfig = {
  aspect: 11 / 5,
  minHeight: 96,
  minWidth: 212,
};

export const breakingNewsL0LConfig = {
  aspect: 3.42,
  minHeight: 96,
  minWidth: 328,
};

export const breakingNewsLNConfig = {
  aspect: 2.34,
  minHeight: 140,
  minWidth: 328,
};

export const headlinesLNConfig = {
  aspect: 1,
  minHeight: 50,
  minWidth: 50,
};

export const roundUpL0MConfig = {
  aspect: 11 / 5,
  minHeight: 96,
  minWidth: 212,
};

export const roundUpL0LConfig = {
  aspect: 41 / 12,
  minHeight: 96,
  minWidth: 328,
};

export const roundUpL1Config = {
  aspect: 9 / 16,
  minHeight: 400,
  minWidth: 225,
};

export const roundUpLNConfig = {
  aspect: 1.64,
  minHeight: 200,
  minWidth: 328,
};

export const viewMoreConfig = {
  aspect: 41 / 23,
  minHeight: 184,
  minWidth: 328,
};

export const l0SmallConfig = {
  aspect: 9 / 8,
  minHeight: 96,
  minWidth: 108,
};

export const newsDestinationImageConfig = {
  aspect: 1,
  minHeight: 68,
  minWidth: 68,
};

export const topStoryDestinationImageConfig = {
  aspect: 41 / 25,
  minHeight: 200,
  minWidth: 328,
};

export const squareImageConfig = {
  aspect: 1,
  minHeight: 100,
  minWidth: 100,
};

export const collectionImageConfig = {
  aspect: 1,
  minHeight: 200,
  minWidth: 200,
};

export const sourceImgDimensionsForModal = {
  width: 706,
  height: 441,
};

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const CARDSTATE = {
  draft: { key: 'Draft', value: 'DRAFT' },
  expired: { key: 'Expired', value: 'EXPIRED' },
  live: { key: 'Live', value: 'LIVE' },
  pendingApproval: { key: 'Pending Approval', value: 'APPROVAL_PENDING' },
  rejected: { key: 'Rejected', value: 'REJECTED' },
  publishingFailed: { key: 'Publishing Failed', value: 'FAILED' },
  scheduled: { key: 'Publishing Scheduled', value: 'PUBLISHING_SCHEDULED' },
  publishingInProgress: {
    key: 'Publishing In Progress',
    value: 'PUBLISHING_IN_PROCESS',
  },
};

export const CURATION_REASON_LIST = [
  { key: 'SWIPE', value: 'swipe' },
  { key: 'TRENDING', value: 'trending' },
  { key: 'SWIPE_EXPERIMENT', value: 'swipe_experiment' },
  { key: 'SWIPE_SCALE', value: 'swipe_scale' },
  { key: 'DEU_EXP', value: 'deu_exp' },
  { key: 'APP_OWNERSHIP', value: 'app_ownership' },
  { key: 'LANDING_PAGE_APPEND_UTM', value: 'landing_page_append_utm' },
  { key: 'RECORDED_LIVE', value: 'recorded_live' },
  { key: 'ESPORTS_NEWS', value: 'esports_news' },
  { key: 'SAMSUNG_LIVE', value: 'samsung_live' },
  { key: 'LIVE_GAME_BOOSTER', value: 'Live Game Booster' },
  { key: 'GAMING_REGULAR', value: 'Gaming_Regular' },
  { key: 'GAMING_RECENCY', value: 'Gaming_Recency' },
  { key: 'GAMING_TOURNAMENTS', value: 'Gaming_Tournaments' },
  { key: 'recorded_live-LStoLD', value: 'recorded_live-LStoLD' },
  { key: 'Recorded_live-BuzzShow', value: 'Recorded_live-BuzzShow' },
  { key: 'Redirection Videos', value: 'Redirection Videos' },
  { key: 'Non-LP', value: 'Non-LP' },
  { key: 'product_marketing', value: 'product_marketing' },
];

export const UTM_IN_URL_ERROR_DESCRIPTION =
  'The URL contains UTMs that conflict with the UTMs that get added by LP Curation Reason. Please remove and try again';

export const PEEKTYPE = {
  ARTICLE: 'Article',
  NATIVE_VIDEO: 'Video',
};

export const MODERATION_STATUS = {
  Safe: '1',
  Unsafe: '0',
};

export const BADGECOLOR: Record<string, string> = {
  Draft: 'grey',
  Expired: 'red',
  Live: 'green',
  pendingApproval: 'gold',
  Rejected: 'red',
  publishingInProgress: 'gold',
  publishingFailed: 'red',
  publishingScheduled: 'blue',
  updateInProgress: 'gold',
  deleted: 'red',
};

export const HTTPSPATTERN = new RegExp('^(https)://', 'i');

export const SELECTED_PUBLISHER_KEY = 'selectedPublisher';

export const INTERNAL_ROLES = [
  'ADMIN',
  'CURATOR',
  'MODERATOR',
  'DESIGNER',
  'PARTNER',
];

export const JIONEXTNIU_CATEGORIES_LIST = [
  { key: 'daily_digest', value: 'Daily Digest' },
  { key: 'cricket', value: 'Cricket' },
  { key: 'sports', value: 'Sports' },
  { key: 'national', value: 'India News' },
  { key: 'entertainment', value: 'Entertainment' },
  { key: 'music', value: 'Music' },
  { key: 'animals', value: 'Nature & Wildlife' },
  { key: 'nature', value: 'Nature' },
];

export const durationTypeMap = {
  1: 'DAYS',
  2: 'HOURS',
  3: 'SECONDS',
};

export const ROUNDUP_BADGE_COLORS: Record<string, string> = {
  'Publishing Scheduled': 'dodgerblue',
  Expired: 'red',
  Live: 'green',
  Draft: 'grey',
  Publishing_Failed: 'red',
};

export const ROUNDUP_CARDSTATES = {
  publishingScheduled: 'Publishing Scheduled',
  expired: 'Expired',
  live: 'Live',
  draft: 'Draft',
};

export const COLLECTIONS_QUICK_FILTERS = [
  // TODO: v1.1 Uncomment to show video cards
  // 'Article',
  // 'Video',
  // 'Politics',
  'Entertainment',
  'Sports',
];

export const ROUNDUP_CARDSELECTION_STATUS = {
  live: 'Live',
  expired: 'Expired',
};

export const QUICK_FILTERS_KEY = {
  Sports: 'SPORTS',
  Entertainment: 'ENTERTAINMENT',
};

export const GCP_CDN_STAGE = 'https://gcs.dev.glance.com';
export const GCP_CDN_PROD = 'https://g.glance-cdn.com';

export const SCREEN_CHAR_LIMIT_SPECS = {
  'Breaking News': { L0M: 40, L0L: 36, LN: 52, L0_SQUARE: 60 },
  Roundup: { L0M: 60, L0L: 64, LN: 52, L0_SQUARE: 60 },
  PublisherName: { L0M: 16, L0L: 16, LN: 16, L0_SQUARE: 16 },
};

export const IMAGE_UPLOAD_SPECS: Record<string, ImageSpecsData> = {
  lockscreen: {
    title: 'Add content Image',
    description: '',
    imageData: {
      aspectWidth: 16,
      aspectHeight: 9,
      size: IMAGE_FILE_SIZE_FEED,
      height: 900,
      width: 1600,
    },
  },
  medium: {
    title: 'Image for L0-Medium',
    description: `Recommended resolution is 636px*288px with file size less than ${IMAGE_FILE_SIZE_SPACES}KB, keep visual elements centered`,
    imageData: {
      size: IMAGE_FILE_SIZE_SPACES,
      height: 288,
      width: 636,
    },
  },
  large: {
    title: 'Image for L0-Large',
    description: `Recommended resolution is 984px*288px with file size less than ${IMAGE_FILE_SIZE_SPACES}KB, keep visual elements centered`,
    imageData: {
      size: IMAGE_FILE_SIZE_SPACES,
      height: 288,
      width: 984,
    },
  },
  ln: {
    title: 'Image for L+N',
    description: `Recommended resolution is 984px*600px with file size less than ${IMAGE_FILE_SIZE_SPACES}KB, keep visual elements centered`,
    imageData: {
      size: IMAGE_FILE_SIZE_SPACES,
      height: 600,
      width: 984,
    },
  },
  roundupBaseImage: {
    title: 'Image for Round-up',
    description: `Recommended ratio is 9:16 (portrait)  with file size less than ${IMAGE_FILE_SIZE_ROUNDUP}KB, keep visual elements centered`,
    imageData: {
      aspectWidth: 9,
      aspectHeight: 16,
      size: IMAGE_FILE_SIZE_ROUNDUP,
      height: 800,
      width: 450,
    },
  },
  lnBreakingNews: {
    title: 'Image for L+N',
    description: `Recommended resolution is 984px*420px with file size less than ${IMAGE_FILE_SIZE_SPACES}KB, keep visual elements centered`,
    imageData: {
      size: IMAGE_FILE_SIZE_SPACES,
      height: 420,
      width: 984,
    },
  },
  // Keeping aspect ratio 9:16 for IPL image as there is an added effort to create a 16:9(landscape) image.
  // Editors have 1440 * 2560 images handy, hence they can use the same to generate widget images as well.
  iplImage: {
    title: 'Image for IPL',
    description: 'Upload the input image for the cropper.',
    imageData: {
      aspectWidth: 9,
      aspectHeight: 16,
      size: IMAGE_FILE_SIZE_FEED,
      height: 2560,
      width: 1440,
    },
  },
  collectionImage: {
    title: 'Upload Image',
    description: '',
    imageData: {
      aspectWidth: 1,
      aspectHeight: 1,
      size: IMAGE_FILE_SIZE_COLLECTION,
      height: 200,
      width: 200,
    },
  },
};

export const SCREENS_WITH_FLUID_DIMENSION = [
  'lockscreen',
  'iplImage',
  'roundupBaseImage',
  'collectionImage',
];

export const IMAGE_TYPES: Record<string, keyof ImageMapType> = {
  lnBreakingNews: 'lPlus',
  roundupBaseImage: 'baseImage',
  medium: 'l0Medium',
  large: 'l0Large',
  ln: 'lPlus',
  lockscreen: 'baseImage',
};

export const PROPOSITONS_LIST: PropositionsType = {
  NEWS: 'News',
  TOP_STORY: 'Top Story',
  POLL: 'Poll',
  PREDICT_THE_WINNER: 'Predict the Winner',
  CHEER_YOUR_TEAM: 'Cheer Your Team',
  WATCH_LIVE: 'Watch Live',
  JC_DOWNLOAD: 'JC Download',
  JC_OPEN: 'JC Open',
  T20_TAB: 'T20 Tab',
};

export const VIDEO_PROPOSITIONS_LIST = {
  JC_DOWNLOAD: 'JC Download',
  JC_OPEN: 'JC Open',
};

export const EVENTS_LIST = {
  IPL: 'IPL',
  WORLD_CUP_2024: 'WORLD_CUP_2024',
};

export const T20_WORLDCUP_COLLECTION = [
  { key: 'INDIA_AT_WORLD_CUP', value: 'India At World Cup' },
  { key: 'WORLD_CUP_BUZZ', value: 'World Cup Buzz' },
  { key: 'MATCH_PREVIEWS', value: 'Match Previews' },
  { key: 'FANTASY_GUIDE', value: 'Fantasy Guide' },
  { key: 'PLAY_OF_THE_DAY', value: 'Play of the Day' },
  { key: 'BEST_MOMENTS', value: 'Best Moments' },
  { key: 'TURNING_POINT', value: 'Turning Point' },
  { key: 'RECORDS_RADAR', value: 'Records Radar' },
  { key: 'TOP_PERFORMERS', value: 'Top Performers' },
  { key: 'TERRIFIC_TONS', value: 'Terrific Tons' },
  { key: 'FANTASTIC_FIFTIES', value: 'Fantastic Fifties' },
  { key: 'MAGICAL_SPELLS', value: 'Magical Spells' },
  { key: 'TRIVIA', value: 'Trivia' },
  { key: 'CONTROVERSIES', value: 'Controversies' },
];

export const IPL_TEAMS = [
  'Rajasthan Royals',
  'Gujarat Titans',
  'Chennai Super Kings',
  'Mumbai Indians',
  'Sunrisers Hyderabad',
  'Lucknow Super Giants',
  'Royal Challengers Bangalore',
  'Punjab Kings',
  'Kolkata Knight Riders',
  'Delhi Capitals',
];

export const WORLD_CUP_TEAMS = [
  'United States',
  'India',
  'Canada',
  'Pakistan',
  'Ireland',
  'Scotland',
  'Australia',
  'Namibia',
  'England',
  'Oman',
  'Afghanistan',
  'West Indies',
  'Uganda',
  'Papua New Guinea',
  'New Zealand',
  'South Africa',
  'Netherlands',
  'Bangladesh',
  'Nepal',
  'Sri Lanka',
  'A1',
  'B2',
  'C1',
  'D2',
];

// Keeping empty string in array because we don't need any widget images now, might need it later once SDK image issue is fixed
export const PROPOSITIONS_IMAGEMAP = {
  TOP_STORY: ['destinationSmall', 'destinationLarge'],
  NEWS: ['destinationSmall'],
  WATCH_LIVE: [''],
  POLL: [''],
  PREDICT_THE_WINNER: [''],
  CHEER_YOUR_TEAM: [''],
  JC_DOWNLOAD: ['jioCinemaMedium'],
  JC_OPEN: ['jioCinemaMedium'],
  T20_TAB: [''],
};

export const PROPOSITIONS_WITHOUT_IMAGE = [
  'POLL',
  'WATCH_LIVE',
  'CHEER_YOUR_TEAM',
  'PREDICT_THE_WINNER',
  'JC_DOWNLOAD',
  'JC_OPEN',
  'T20_TAB',
];

export const GLANCE_CONTEXTS = [
  'Live',
  'Featured',
  'Launch',
  'Nearby',
  'Viral',
  'Hot',
  'Trending',
  'Hatke',
  'Exclusive',
  'Fun',
  'Popular',
  'Breaking News',
  'Category',
];

export const CONTENT_TYPE = {
  INFO: 'Info',
  ACTION: 'Action',
};

export const ZAPP_WIDGET_IDS_COLLECTIONS_MAP = [
  { value: 'ARTICLE_COLLECTION_XL_V1', label: 'NEWS' },
  { value: 'SPORTS_ARTICLE_COLLECTION_XL_V1', label: 'SPORTS' },
  { value: 'FINANCE_ARTICLE_COLLECTION_XL_V1', label: 'FINANCE' },
];

// Need to hard code for now, BE will expose an api for it later
export const ZAPP_WIDGET_IDS_NEWS = {
  BREAKING_NEWS_V1: 'GLANCE_BREAKING_NEWS_TAGGED_ARTICLE_LH_V1',
  BREAKING_NEWS_V2: 'GLANCE_BREAKING_NEWS_TAGGED_ARTICLE_LH_V2',
  TOP_STORIES: 'TRENDZ_TOP_STORY_CATEGORIZED_LV_XXL_V1',
};

// Need to hard code for now, BE will expose an api for it later
export const ZAPP_WIDGET_IDS_SPORTS: Record<string, string> = {
  NBA: 'SPORTS_NBA_HEADLINES_XXL_V1',
  NFL: 'SPORTS_NFL_HEADLINES_XXL_V1',
  NHL: 'SPORTS_NHL_HEADLINES_XXL_V1',
  MLB: 'SPORTS_MLB_HEADLINES_XXL_V1',
  CFB: 'SPORTS_CFB_HEADLINES_XXL_V1',
  CBK: 'SPORTS_CBK_HEADLINES_XXL_V1',
  BUNDESLIGA: 'SPORTS_BDS_HEADLINES_XXL_V1',
};

export const L0_ZAPP_WIDGET_IDS = {
  BREAKING_NEWS: ['L0_BREAKING_NEWS_TAGGED_ARTICLE_LN_V1'],
  TOP_STORIES: [
    'L0_TRENDZ_NEWS_HEADLINES_LN_V1',
    'L0_TRENDZ_NEWS_HEADLINES_MD_V1',
  ],
  SPORTS: ['L0_SPORTS_HEADLINES_LN_V1', 'L0_SPORTS_HEADLINES_MD_V1'],
};

export const EVENT_TYPES = {
  insert: 'INSERT',
  update: 'UPDATE',
  delete: 'DELETE',
};

export const SPACES_LIST = {
  NEWS: 'News',
  SPORTS: 'Sports',
  FINANCE: 'Finance',
};

export const MAX_SELECTED_TEAMS = 2;
export const BUNDESLIGA = 'Bundesliga';
export const IPL_LEAGUE = 'IPL';

export const SPACE_SELECTION_LIMIT = 1;

export const DEFAULT_EU = { eu: 'Trends & Sports', euId: 'euid_74799679' };

export const PREVIEW_SCREEN_NAMES = {
  L0_SQUARE: 'L0_SQUARE',
};

export const PARTNER_IDS = {
  XIAOMI: '6c0df13798382e1044a104a8f984edb130b20ce6',
  MOTOROLA: 'eee86d7cb6b40eeabd6858f4a88e4374',
  MOTOROLA_GO: '2bea64040f41b17332d7a67fadeae336',
  REALME: 'a07a917cae67e8a206bf9bdde2da9aed',
  REALME_ANDROID_GO: 'dde1b816a19d16a8b866ff74319d7995',
  TRACFONE: 'd41d8cd98f00b204e9800998ecf8427e',
  DEUTSCHE_TELEKOM: '70ca7b2dc4342128b2aac0751b0b60f8',
  SAMSUNG_DLSS: 'bac8164d2982a5afbac64c57b64f9688',
  SAMSUNG_ANDROID: '16b66d7123dd588670e011bbb88bec9c0a27359c',
  OPPO_API: 'b27cbb02e015198844783efe6db1d580',
  JIO_NEXT_NIU: '8aa5e6d1bea2ee2ae3f3f148c70e4cc3',
  TRANSSION: 'b366fc7948b55d41976cf3014f616f6f',
  VIVO: 'feb4e5ad0039fee86e4870dd3ca88c28a8ee1a1e',
};

export const REGION_EU_MAP = {
  IN: {
    STAGE: 'euid_74799679',
    PROD: 'euid_0d04cc23',
  },
  ID: {
    STAGE: 'euid_424853d9',
    PROD: 'euid_f7ec4369',
  },
  US: {
    STAGE: 'euid_0448ba34',
    PROD: 'euid_644c19e0',
  },
  MX: {
    STAGE: 'euid_32f2cc1d',
    PROD: 'euid_1acdea16',
  },
  BR: {
    STAGE: 'euid_70e7d40f',
    PROD: 'euid_6f6b55dc',
  },
  CO: {
    STAGE: 'euid_e5aecb11',
    PROD: 'euid_f5a3a6a2',
  },
  JP: {
    STAGE: 'euid_db05c0c7',
    PROD: 'euid_5a669a56',
  },
  DE: {
    STAGE: 'euid_b02df604',
    PROD: 'euid_5e19bc34',
  },
  AT: {
    STAGE: 'euid_d78f28d0',
    PROD: 'euid_7c67ed10',
  },
  TH: {
    STAGE: 'euid_002e69bd',
    PROD: 'euid_d61feba6',
  },
  PH: {
    STAGE: 'euid_9cfad40b',
    PROD: 'euid_72ee5ec8',
  },
  MY: {
    STAGE: 'euid_fce12dde',
    PROD: 'euid_150ae374',
  },
  VN: {
    STAGE: 'euid_bfce0c56',
    PROD: 'euid_5be6c56d',
  },
};

export const DT_COUNTRIES = ['DE', 'AT'];

export const SPACES_ENABLED_REGIONS = ['US', 'DE', 'AT', 'IN', 'ID'];

export const DEFAULT_PARTNER_IDS_HIGHLIGHTS: Record<string, string[]> = {
  IN: [
    PARTNER_IDS.XIAOMI,
    PARTNER_IDS.REALME,
    PARTNER_IDS.REALME_ANDROID_GO,
    PARTNER_IDS.MOTOROLA,
    PARTNER_IDS.MOTOROLA_GO,
    PARTNER_IDS.SAMSUNG_ANDROID,
    PARTNER_IDS.SAMSUNG_DLSS,
    PARTNER_IDS.JIO_NEXT_NIU,
    PARTNER_IDS.OPPO_API,
  ],
  US: [PARTNER_IDS.TRACFONE],
};

export const DEFAULT_PARTNER_IDS_SPACES: Record<string, string[]> = {
  IN: [PARTNER_IDS.MOTOROLA, PARTNER_IDS.REALME],
  US: [PARTNER_IDS.MOTOROLA, PARTNER_IDS.TRACFONE],
  DE: [PARTNER_IDS.DEUTSCHE_TELEKOM],
  AT: [PARTNER_IDS.DEUTSCHE_TELEKOM],
  ID: [PARTNER_IDS.REALME],
};

export const VX_ENABLED_REGIONS = ['IN', 'ID'];

// Sending blur version of L0Large and small1X1 for now, we'll add others later if required
export const SPACES_IMAGE_TYPES: Record<string, string> = {
  L0M: 'l0Medium',
  L0L: 'l0Large',
  LN: 'lPlus',
  LN_HEADLINE: 'lPlus',
  VIEW_MORE: 'viewMore',
  SQUARE_IMAGE: 'small1X1',
  // L0M_BLUR: 'l0MediumBlur',
  L0L_BLUR: 'l0LargeBlur',
  // LN_BLUR: 'lPlusBlur',
  // LN_HEADLINE_BLUR: 'lPlusBlur',
  // VIEW_MORE_BLUR: 'viewMoreBlur',
  SQUARE_IMAGE_BLUR: 'small1X1Blur',
  COLLECTION_IMAGE: 'collectionImage',
};

export const VX_CATEGORY = 'sports';

export const STATUS_LIST = {
  SUCCESS: 'success',
  FAIL: 'failure',
};

export const DEFAULT_PARTNER_IDS_L0_ONLY = [
  PARTNER_IDS.OPPO_API,
  PARTNER_IDS.TRANSSION,
  PARTNER_IDS.VIVO,
];

export const getVxEU = () => {
  const countryCode = getCountryCode();
  return isNonProdEnv()
    ? REGION_EU_MAP[countryCode].STAGE
    : REGION_EU_MAP[countryCode].PROD;
};

export const getVXUrl = (cardId: string | undefined): string => {
  if (!cardId) {
    return '';
  }
  const macrosString =
    'utm_medium=$UTM_MEDIUM&glanceId=$GLANCE_ID&gl_imp_id=$IMP_ID&userId=$USER_ID&apiKey=$API_KEY&sdkv=$BUILD_VERSION&gpid=$GPID';
  return isNonProdEnv()
    ? `https://trends.staging.glance.com/sports/vx/skenes/${cardId}?${macrosString}`
    : `https://trends.glance.com/sports/vx/skenes/${cardId}?${macrosString}`;
};

export const MAX_TIME_FOR_GLANCIFICATION = 5;

export const INITIAL_APP_CONFIG_DATA = {
  cdnConfig: {
    imageHost: '',
  },
  spaceConfig: {
    GAMING: {
      // Note: We do not publish Gaming spaces as of now, hence no Widget Ids
      l0ZappWidgetIds: {},
      zappWidgetIds: {},
    },
    NEWS: {
      l0ZappWidgetIds: {
        BREAKING_NEWS: [],
        TOP_STORIES: [],
      },
      zappWidgetIds: {
        BREAKING_NEWS: [],
        TOP_STORIES: [],
        LOCAL_NEWS: [],
      },
    },
    SPORTS: {
      l0ZappWidgetIds: {
        SPORTS: [],
      },
      zappWidgetIds: {
        BUNDESLIGA: [],
        NBA: [],
        MLB: [],
        NFL: [],
        NHL: [],
        CBK: [],
        CFB: [],
        IPL: [],
      },
    },
  },
  collectionConfig: {
    tags: [],
    states: {},
    zappMapping: {},
    previewConfig: {
      static: 30,
      dynamic: 20,
    },
  },
};

export const CATEGORY_WIDGET_ID_MAP = {
  STAGE: {
    NATIONAL_NEWS_LG_V1: [
      'POLITICS',
      'spcid_46b97022',
      'spcid_86fce98f',
      'spcid_a63eb5d9',
    ],
    BUSINESS_NEWS_LG_V1: [
      'BUSINESS',
      'spcid_a396c79c',
      'spcid_98b01d0b',
      'spcid_93fe1d41',
    ],
    WORLD_NEWS_LG_V1: ['spcid_96d5a163', 'INTERNATIONAL'],
    ENTERTAINMENT_NEWS_LG_V1: [
      'ENTERTAINMENT',
      'ENTERTAINMENT_IN',
      'spcid_be282d59',
      'spcid_cf1d22fe',
      'spcid_b8d717b9',
      'spcid_eb9a6213',
    ],
    SPORTS_NEWS_LG_V1: [
      'SPORTS',
      'spcid_7bb2eced',
      'spcid_310246bf',
      'spcid_4f21b7fb',
      'spcid_6a7b6a58',
    ],
    LIFESTYLE_NEWS_LG_V1: [
      'spcid_ed622342',
      'spcid_f22dcd61',
      'spcid_7bf718c3',
      'spcid_4e5feb31',
      'LIFESTYLE',
    ],
  },
  PROD: {
    NATIONAL_NEWS_LG_V1: [
      'spcid_647dc620',
      'POLITICS',
      'spcid_6a0e1074',
      'spcid_381d8a2b',
    ],
    BUSINESS_NEWS_LG_V1: [
      'BUSINESS',
      'spcid_2b423028',
      'spcid_5c50eda1',
      'spcid_965310ae',
      'spcid_4e5a1f28',
    ],
    WORLD_NEWS_LG_V1: ['INTERNATIONAL', 'spcid_4b469c24', 'spcid_775955e6'],
    ENTERTAINMENT_NEWS_LG_V1: [
      'spcid_e8b51cc8',
      'ENTERTAINMENT',
      'spcid_473a0bbe',
      'spcid_d4ee6c97',
      'spcid_f07dfecf',
    ],
    SPORTS_NEWS_LG_V1: [
      'spcid_87cdebf6',
      'spcid_2998ed96',
      'spcid_120bee2a',
      'spcid_36731af2',
      'SPORTS',
    ],
    LIFESTYLE_NEWS_LG_V1: [
      'LIFESTYLE',
      'spcid_f6945f65',
      'spcid_f11afcb7',
      'spcid_a00b983f',
      'spcid_60bbd256',
    ],
  },
};

export const PUBLISHER_TYPES = {
  NATIONAL: 'national',
  REGIONAL: 'regional',
};
